import React from "react"
import { InlineWidget } from "react-calendly"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import ContactForm from "../components/ContactForm"

import heroMp4 from "../videos/socialmindr-bg-video.mp4"
import heroWebm from "../videos/socialmindr-bg-video.webm"

import EventRoundedIcon from "@material-ui/icons/EventRounded"
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded"
import Seo from "../components/Seo"

export default function ContactUs() {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <Hero
        video
        videoMp4={heroMp4}
        videoWebm={heroWebm}
        title="Contact Us"
        titleClassName="w-auto"
      >
        <div className="text-white mt-4 lg:mt-8 mb-7 sm:mb-3 md:mb-7 sm:w-11/12 md:w-auto lg:leading-relaxed">
          <p>Contact us to build your future.</p>
        </div>
      </Hero>
      <section className="section min-h-0 mt-10">
        <div className="grid grid-cols-2 gap-12 place-items-start">
          <AnchorLink
            to="/contact-us#calendly"
            className="flex flex-col items-center justify-center text-center"
          >
            <div className="w-20 mb-4">
              <div className="w-full pb-full relative">
                <div className="absolute inset-0">
                  <div className="p-4 w-full h-full flex flex-col justify-center items-center bg-primary text-white text-5xl rounded-full">
                    <EventRoundedIcon color="inherit" fontSize="inherit" />
                  </div>
                </div>
              </div>
            </div>
            <p>Book a Free Consultation</p>
          </AnchorLink>
          <AnchorLink
            to="/contact-us#contact"
            className="flex flex-col items-center justify-center text-center"
          >
            <div className="w-20 mb-4">
              <div className="w-full pb-full relative">
                <div className="absolute inset-0">
                  <div className="p-4 w-full h-full flex flex-col justify-center items-center bg-primary text-white text-5xl rounded-full">
                    <MailOutlineRoundedIcon
                      color="inherit"
                      fontSize="inherit"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p>Send Us a Message</p>
          </AnchorLink>
        </div>
      </section>
      <section id="calendly" className="section">
        <div className="w-full h-full">
          <InlineWidget url="https://calendly.com/socialmindr/30minconsultation" />
        </div>
      </section>
      <section className="section min-h-0">
        <ContactForm />
      </section>
    </Layout>
  )
}
